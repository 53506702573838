<template>
  <b-card title="E-Posta Gönder">
    <b-alert
      v-if="sending"
      show
      variant="info"
    >
      <div class="alert-body py-2">
        <pre-loading text-color="text-info" />
      </div>
    </b-alert>
    <b-alert
      v-else-if="sendStatus.message"
      show
      :variant="sendStatus.status? 'success' : 'danger'"
    >
      <div class="alert-body py-2 text-center">
        <FeatherIcon
          icon="MailIcon"
          size="24"
        />
        <div class="font-medium-2">
          {{ sendStatus.message }}
        </div>
      </div>
    </b-alert>
    <div v-else>
      <b-form-group
        label="Kime"
        label-for="mailto"
      >
        <b-form-tags
          id="mailto"
          v-model="dataItem.mailto"
          placeholder="Kime"
          add-button-text="Ekle"
          remove-on-delete
        />
      </b-form-group>
      <b-form-group
        label="Bilgi"
        label-for="mailcc"
      >
        <b-form-tags
          id="mailcc"
          v-model="dataItem.mailcc"
          placeholder="Bilgi"
          add-button-text="Ekle"
          remove-on-delete
        />
      </b-form-group>
      <b-form-group
        label="Gizli Kopya"
        label-for="mailbcc"
      >
        <b-form-tags
          id="mailbcc"
          v-model="dataItem.mailbcc"
          placeholder="Gizli Kopya"
          add-button-text="Ekle"
          remove-on-delete
        />
      </b-form-group>
      <b-form-group
        label="Konu"
        label-for="mailsubject"
      >
        <b-form-input
          id="mailsubject"
          v-model="dataItem.mailsubject"
          placeholder="Konu"
        />
      </b-form-group>
      <b-form-group
        label="E-Posta"
        label-for="body"
      >
        <quill-editor
          v-model="dataItem.mailbody"
          :options="editorOption"
          class="bg-white text-black-50"
        >
          <div
            id="email_toolbar"
            slot="toolbar"
          >
            <editor-toolbar />
          </div>
        </quill-editor>
      </b-form-group>
      <b-alert show>
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" /> Teklif dosyası e-posta eki olarak gönderilecektir.
        </div>
      </b-alert>
      <b-button
        variant="primary"
        :disabled="dataItem.mailto.length === 0 || !dataItem.mailsubject"
        @click="sendMail"
      >
        <FeatherIcon icon="SendIcon" />
        Gönder
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BButton, BFormTags, BAlert,
} from 'bootstrap-vue'
import EditorToolbar from '@/layouts/components/common/EditorToolbar.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
// eslint-disable-next-line import/order
import { quillEditor } from 'vue-quill-editor'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'MailForm',
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BFormTags,
    BButton,
    BAlert,
    EditorToolbar,
    PreLoading,
    quillEditor,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: '#email_toolbar',
        },
        theme: 'snow',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoicesMail/dataItem']
    },
    sending() {
      return this.$store.getters['proformaInvoicesMail/sending']
    },
    sendStatus() {
      return this.$store.getters['proformaInvoicesMail/dataSaveStatus']
    },
  },
  methods: {
    sendMail() {
      this.dataItem.id_proforma_invoices = this.$route.params.id
      this.$store.dispatch('proformaInvoicesMail/sendMail', this.dataItem)
        .then(res => {
          if (res) {
            this.$store.dispatch('proformaInvoicesMail/getDataList', {
              where: {
                'proforma_invoices_mailsend.id_proforma_invoices': this.$route.params.id,
              },
            })
          }
        })
    },
  },
}
</script>
