<template>
  <div>
    <b-card no-body>
      <proforma-summary />
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Ürünler</b-card-title>
      </b-card-header>
      <products />
      <b-card-footer>
        <div class="mt-1 text-center d-flex justify-content-between">
          <div class="text-left">
            <div class="d-inline">
              <b-button
                variant="warning"
                class="mr-2"
                :to="'/app/proforma-invoices/edit/' + dataItem.id"
              >
                <FeatherIcon icon="EditIcon" />
                Güncelle
              </b-button>
            </div>
          </div>
          <div class="text-right">
            <div class="d-inline">

              <b-dropdown
                id="print"
                variant="success"
              >
                <template #button-content>
                  <FeatherIcon icon="PrinterIcon" />
                  Yazdır
                </template>
                <b-dropdown-item @click="download('tr')">
                  Türkçe
                </b-dropdown-item>
                <b-dropdown-item @click="download('en')">
                  İngilizce
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div
            class="d-inline"
          >
            <b-button
              variant="danger"
              class="mr-2"
              @click="toggleMailForm"
            >
              <FeatherIcon icon="MailIcon" />
              E-posta
            </b-button>
          </div>
        </div>
      </b-card-footer>
    </b-card>
    <b-card
      v-if="dataItem.terms"
      title="Özel Şartlar"
    >
      <div v-html="dataItem.terms" />
    </b-card>
    <mail-form v-if="emailForm" />
    <mail-logs v-if="emailForm" />
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BCardFooter, BCardTitle, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ProformaSummary from '@/views/Admin/Proforma_invoices/View/ProformaSummary.vue'
import Products from '@/views/Admin/Proforma_invoices/View/Products.vue'
import MailForm from '@/views/Admin/Proforma_invoices/View/MailForm.vue'
import MailLogs from '@/views/Admin/Proforma_invoices/View/MailLogs.vue'

export default {
  name: 'OffersView',
  components: {
    MailLogs,
    MailForm,
    BCard,
    BCardHeader,
    BCardFooter,
    BCardTitle,
    BButton,
    ProformaSummary,
    Products,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      emailForm: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
  },
  methods: {
    download(lang) {
      window.open(`${this.$store.state.app.baseURL}/Exports/proforma/pdf?token=${localStorage.getItem('downloadToken')}&id=${this.$route.params.id}&lang=${lang}`, '_blank')
    },
    toggleMailForm() {
      this.emailForm = !this.emailForm
    },
  },
}
</script>
