<template>
  <div>
    <b-card-header class="d-block">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-card-title>#{{ dataItem.pno }}</b-card-title>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="text-right text-primary font-weight-bolder font-medium-3"
        >
          {{ moment(dataItem.pdate).format('LL') }}
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mb-1"
        >
          <div class="font-weight-bold font-medium-2 text-primary">
            Ödeme Şekli
          </div>
          {{ dataItem.payment_type }}
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-1"
        >
          <div class="font-weight-bold font-medium-2 text-primary">
            Teslim Yeri
          </div>
          {{ dataItem.delivery_location }}
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-1"
        >
          <div class="font-weight-bold font-medium-2 text-primary">
            Teslim Şekli
          </div>
          {{ dataItem.delivery_type }}
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-1"
        >
          <div class="font-weight-bold font-medium-2 text-primary">
            Menşei
          </div>
          {{ dataItem.origin }}
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-1"
        >
          <div class="font-weight-bold font-medium-2 text-primary">
            Termin Süresi
          </div>
          {{ dataItem.deadline }}
        </b-col>

        <b-col
          cols="12"
          class="mb-1"
        >
          <div class="font-weight-bold font-medium-2 text-primary">
            Banka Hesap Bilgisi
          </div>
          {{ dataItem.bank_account }}
          <div class="text-danger font-medium-2 font-weight-bold">
            {{ dataItem.bank_iban }}
          </div>
        </b-col>
        <hr>
        <b-col cols="12">
          <b-alert
            variant="dark"
            show
          >
            <div class="alert-body">
              <div class="font-weight-bold">
                Notlar
              </div>
              <div>
                {{ dataItem.notes }}
              </div>
            </div>
          </b-alert>
        </b-col>
        <b-col
          v-if="dataItem.id_offers"
          cols="12"
        >
          <b-alert
            variant="info"
            show
          >
            <div class="alert-body">
              <div class="text-center d-flex justify-content-between">
                <div class="d-flex align-content-around align-items-center">
                  <FeatherIcon
                    icon="InfoIcon"
                  />
                  <div class="ml-1">
                    Proforma fatura {{ dataItem.offer_number }} numaralı tekliften oluşturulmuştur.
                  </div>
                </div>
                <div>
                  <b-button
                    :to="'/app/offers/view/' + dataItem.id_offers"
                    variant="info"
                    size="sm"
                  >
                    Teklifi Görüntüle
                  </b-button>
                </div>
              </div>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BRow, BCol, BCardHeader, BCardTitle, BCardBody, BAlert, BButton,
} from 'bootstrap-vue'

export default {
  name: 'ProformaSummary',
  components: {
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAlert,
    BButton,
  },
  computed: {
    dataItem() {
      return this.$store.getters['proformaInvoices/dataItem']
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
